import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
      meta: {
        title: '考核详情',
      },
    },
    {
      /** 提交预约 */
      path: '/reservation',
      name: 'Reservation',
      component: () => import('@/views/Reservation.vue'),
      meta: {
        title: '提交预约',
      },
    },
    {
      /** 线下考试成绩表 */
      path: '/achievement',
      name: 'Achievement',
      component: () => import('@/views/Achievement.vue'),
      meta: {
        title: '线下考试成绩表',
      },
    },
    {
      /** 线上考试成绩表 */
      path: '/OnlineAchievement',
      name: 'OnlineAchievement',
      component: () => import('@/views/OnlineAchievement.vue'),
      meta: {
        title: '线上考试成绩表',
      },
    },
    {
      /** 线上考试考前准备 */
      path: '/assessment',
      name: 'Assessment',
      component: () => import('@/views/Assessment.vue'),
      meta: {
        title: '线上考试考前准备',
      },
    },
    {
      /** 线上考试题目 */
      path: '/onlinetest',
      name: 'OnlineTest',
      component: () => import('@/views/OnlineTest.vue'),
      meta: {
        title: '线上考试题目',
      },
    },
    {
      /** 课程详情内容部分 */
      path: '/details',
      name: 'Details',
      component: () => import('@/views/details.vue'),
      meta: {
        title: '课程详情内容部分',
      },
    },
    {
      /** 题目答案对比 */
      path: '/getanswer',
      name: 'getAnswer',
      component: () => import('@/views/getAnswer.vue'),
      meta: {
        title: '题目答案对比',
      },
    },
  ],
});

// beforeEach是router的钩子函数，在进入路由前执行
router.beforeEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title as string;
  }
  return true;
});

export default router;
