import './styles/index.scss';
import './plugins/interceptors';

import { createApp } from 'vue';
import eruda from 'eruda';
import App from './App.vue';
import router from './router';
import store from './store';
import vant from './plugins/vant';

const app = createApp(App);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
app.config.devtools = true;

app.use(router).use(vant).use(store).mount('#app');

// eslint-disable-next-line import/no-extraneous-dependencies
// eruda.init();

// window.addEventListener('beforeunload', (e) => {
//   const dialogText = '重新加载页面会丢失当前进度的答案，如无必要请取消操作';
//   e.returnValue = dialogText;
//   return dialogText;
// });
