import { useLocalStorage } from '@vueuse/core';

const videoRecord = {
  state: {
    video: {},
  },
  mutations: {
    set_video(state: any, video: any): void {
      state.video = video;
      const formId = localStorage.getItem('formId');
      if (formId) {
        localStorage.setItem(formId, JSON.stringify(video));
      }
    },
  },
  actions: {},
};
export default videoRecord;
