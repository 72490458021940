<template>
  <!-- <router-view /> -->
  <suspense>
    <router-view />
    <template #fallback>
      <div>Loading...</div>
    </template>
  </suspense>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style>
html,
body {
  font-family: PingFang SC, sans-serif;
}
</style>
