import { App } from 'vue';
import {
  Button,
  Image,
  Circle,
  Swipe,
  SwipeItem,
  Lazyload,
  Rate,
  ActionSheet,
  Cell,
  Field,
  Picker,
  Popup,
  Tabs,
  Tab,
  Icon,
  Collapse,
  CollapseItem,
  Dialog,
} from 'vant';

const components = [
  Button,
  Image,
  Circle,
  Swipe,
  SwipeItem,
  Lazyload,
  Rate,
  ActionSheet,
  Cell,
  Field,
  Picker,
  Popup,
  Tabs,
  Tab,
  Icon,
  Collapse,
  CollapseItem,
  Dialog,
];

const install = (app: App): void => {
  components.forEach((component) => {
    app.use(component);
  });
};

export default {
  install,
};
