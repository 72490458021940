import { setInterceptorsRequest, setInterceptorsResponse } from '@/libs/http';

import { AxiosError } from 'axios';
import { Toast } from 'vant';

setInterceptorsRequest((config) => {
  console.log('setInterceptorsRequest===>>');

  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 0,
  });
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }
  // config.headers.Authorization =
  //   'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODE4MDQzNzEsInVzZXJfbmFtZSI6IjEzNzQ4NjA2MDA3LS0yIiwianRpIjoiYWJjMmQ5NWUtZmJkNi00YTYwLWJhYTMtNGU1MmE3ZDUyYTRhIiwiY2xpZW50X2lkIjoiZ29vZGNvYWNoIiwic2NvcGUiOlsiYWxsIl0sInRva2VuIjoiZXlKMElqb3lMQ0p1WVcxbElqb2k1YkNQNUxpRElpd2lZM0p2YzNNaU9tWmhiSE5sTENKd2FXUWlPbHRkTENKdmFXUWlPbHN4TlRFME56YzJOalV3TkRBMU9UQTBNemcwWFN3aWFXUWlPakUxTVRRM09ESTFNVGcxTXpBNE1ERTJOalFzSW5OcFpDSTZXekUxTVRRM056WTROamM1Tnpjd016VTNOelpkTENKMFl5STZJazFuSWl3aWMzUmhkSFZ6SWpveGZRPT0ifQ.eGcctl3bPz2nf08yRrXjn_RUBgpQJIIdeSWc1VXuEPA';
  return config;
});
// 添加响应拦截器
setInterceptorsResponse(
  (res) => {
    console.log('setInterceptorsResponse===>');
    const { data, status } = res;
    Toast.clear();
    if (data.code === 500) {
      Toast({ type: 'fail', message: data.msg });
    }

    // 状态码为200，有响应且响应的代码为0是返回resolve
    return status === 200 && [0].includes(data.code)
      ? Promise.resolve(data.data)
      : Promise.reject(res.data.data);
  },
  (error: AxiosError) => {
    // 响应错误时处理异常信息
    console.error(`Method: ${error.config?.method}, path: ${error.config?.url}. ${error.message}.`);
    Toast.fail('接口错误！');
    throw error;
  },
);
setInterceptorsResponse(
  (res) => res,
  (error) => {
    console.log(error);
  },
);
