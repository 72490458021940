import { isDev } from '@/utils/is';
import { AxiosRequestConfig } from 'axios';
// TODO:请求IP设置
const config: AxiosRequestConfig = {
  timeout: 1000 * 60,
  baseURL: isDev ? '/api' : import.meta.env.VITE_APP_API_PROD,
  // baseURL: import.meta.env.VITE_APP_API_PROD,
  // withCredentials: true,
};

export default config;
